
















import Vue from 'vue'
import ProviderConnectButton from '@/components/Auth/ProviderConnectButton.vue'
import firebase from '@/plugins/firebase'
import {
  SocialMedia,
} from './types'

export default Vue.extend({
  components: {
    ProviderConnectButton,
  },
  data: () => ({
    providers: [
      {
        name: `Facebook`,
        color: `indigo`,
        icon: `mdi-facebook`,
        provider: new firebase.auth.FacebookAuthProvider(),
      },
      {
        name: `Google`,
        color: `grey`,
        icon: `mdi-google`,
        provider: new firebase.auth.GoogleAuthProvider(),
      },
      {
        name: `Twitter`,
        color: `light-blue`,
        icon: `mdi-twitter`,
        provider: new firebase.auth.TwitterAuthProvider(),
      },
    ] as SocialMedia[],
  }),
})
