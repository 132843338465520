














































































import Vue from 'vue'
import ProviderConnectButtons from '@/components/Auth/ProviderConnectButtons.vue'
import {
  authStore,
  snackbarStore,
} from '@/store'

export default Vue.extend({
  components: {
    ProviderConnectButtons,
  },
  data: () => ({
    email: ``,
    password: ``,
    loading: false,
  }),
  methods: {
    async signIn () {
      const {
        email,
        password,
      } = this
      try {
        this.loading = true
        await authStore.signInWithEmailAndPassword({
          email, password,
        })
        this.$router.replace(`/`)
      } catch (err) {
        snackbarStore.addSiteError({
          err,
          snackMessage: this.$t(`snackMessages.error.signIn`),
        })
      }
      this.loading = false
    },
  },
})
