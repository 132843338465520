

















import Vue from 'vue'
import {
  SocialMedia,
} from './types'
import {
  PropValidator,
} from 'vue/types/options'
import {
  authStore,
  snackbarStore,
} from '@/store'

export default Vue.extend({
  props: {
    provider: {
      type: Object,
      default: () => ({
      }),
    } as PropValidator<SocialMedia>,
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    async connect () {
      if (!this.provider.provider) return
      try {
        this.loading = true
        await authStore.signInWithProvider(this.provider.provider)
        this.$router.replace(`/`)
      } catch (err) {
        snackbarStore.addSiteError({
          err,
          snackMessage: this.$t(`snackMessages.error.signIn`),
        })
      }
      this.loading = false
    },
  },
})
